<template>
  <div class="pageheader-right">
    <div class="d-flex align-items-center gap-1 filter-by-color">
      <b-form-group class="mb-0" label="" label-for="status-filter">
        <select class="form-control" v-model="form.status">
          <option
            v-for="option in filterOptions"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </b-form-group>
      <div class="d-flex align-items-center gap-1 filter-by-color">
        <p>{{ $t("Status") }}</p>
        <b-form-group class="mb-0">
          <select class="form-control" v-model="form.caseStatus">
            <option
              v-for="option in caseStatusOptions"
              :value="option.value"
              :key="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </b-form-group>
      </div>
    </div>
    <div class="d-flex align-items-center gap-1 filter-by-color">
      <p>{{ $t("Per page") }}</p>
      <b-form-group class="m-0">
        <select class="form-control" v-model="form.perPage">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
        </select>
      </b-form-group>
    </div>
    <div class="search">
      <div class="icon">
        <ElementIcon v-bind:icon="'searchIcon'" />
      </div>
      <input
        type="text"
        v-model="form.search"
        :placeholder="$t('Search for...')"
      />
    </div>
    <div class="divider"></div>
    <div class="d-flex align-items-center gap-1 filter-by-color">
      <p>{{ $t("Filter by") }}</p>
      <b-form-group class="m-0">
        <select class="form-control" v-model="form.selectedFilter">
          <option value="all">{{ $t("All") }}</option>
          <option
            v-for="flag in existingFlags"
            :key="flag.id"
            :value="flag.id"
            :style="{ color: flag.color }"
          >
            {{ flag.name }}
          </option>
        </select>
      </b-form-group>
    </div>
    <div class="d-flex align-items-center gap-1 filter-by-color">
      <p>{{ $t("At least") }}</p>
      <StarRating
        :value="filterRatingValue"
        @setRatingValue="applyRatingFilter"
      />
    </div>
  </div>
</template>

<script>
import StarRating from "@/components/elements/StarRating.vue";
import ElementIcon from "@/components/elements/Icon.vue";
export default {
  props: {
    form: Object,
    existingFlags: Array,
  },
  components: {
    StarRating,
    ElementIcon,
  },
  data() {
    return {
      filterOptions: [
        { value: "all", text: "All" },
        { value: "active", text: "Active" },
        { value: "finished", text: "Finished" },
        { value: "flagged", text: "Flagged" },
        { value: "trash", text: "Trash" },
      ],
      filterRatingValue: null,
      caseStatusOptions: [
        { value: "all", text: "All" },
        { value: "done", text: "Done" },
        { value: "failed", text: "Failed" },
        { value: "hidden", text: "Hidden" },
        { value: "in-progress", text: "In Progress" },
        { value: "in-approval", text: "In Approval" },
        { value: "queued", text: "Queued" },
        { value: "unsupported", text: "Unsupported" }
      ],
    };
  },
  methods: {
    applyRatingFilter(event) {
      this.$emit("apply-rating-filter", event);
    },
  },
};
</script>
