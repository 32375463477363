<template>
  <div class="checkbox-s-group">
    <input
      type="checkbox"
      class="checkbox-input"
      id="selectAll"
      v-model="selectAll"
      @change="toggleSelectAll"
    />
    <label
      for="selectAll"
      class="checkbox-label m-0 text-white"
      :title="$t('Select All')"
    >
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectAll: false,
    };
  },
  methods: {
    toggleSelectAll() {
      this.$emit("toggleSelectAll", this.selectAll);
    },
  },
};
</script>
