var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.row)?_c('div',{staticClass:"d-flex align-items-center justify-content-center action"},[(_vm.canDeleteFinally)?_c('div',{staticClass:"cursor-pointer mr-1",attrs:{"title":_vm.$t('Delete finally')},on:{"click":function($event){return _vm.deleteCase(_vm.row.id)}}},[_c('feather-icon',{staticStyle:{"color":"red"},attrs:{"size":"16","icon":"TrashIcon"}})],1):_vm._e(),(_vm.canEdit)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push(("/my-cases/" + (_vm.row.id) + "/edit"))}}},[_c('feather-icon',{attrs:{"size":"16","icon":"Edit2Icon"}})],1):_vm._e(),_c('b-nav-item-dropdown',{attrs:{"right":"","toggle-class":"d-flex align-items-center"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{},[_c('ElementIcon',{attrs:{"icon":'barVerticalIcon'}})],1)]},proxy:true}],null,false,2854047803)},[(_vm.canDownloadOriginalAI)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadFiles([_vm.row.files['upper'], _vm.row.files['lower']])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Download Original used for AI")))])],1):_vm._e(),(_vm.canDownloadOriginal)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadFiles([
          _vm.row.files['upper_portal_original'],
          _vm.row.files['lower_portal_original'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Download Original")))])],1):_vm._e(),(_vm.canPreviewOriginalRegistered)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.handlePreviewClick(
          _vm.row.files['upper_portal'],
          _vm.row.files['lower_portal']
        )}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"InfoIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Preview Original Registered")))])],1):_vm._e(),(_vm.canDownloadOriginalRegistered)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadRegisteredFiles([
          _vm.row.files['upper_portal'],
          _vm.row.files['lower_portal'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Download Original Registered")))])],1):_vm._e(),(_vm.row.results)?_c('div',[_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.handlePreviewResultClick([
            _vm.row.results['upper_rotated'],
            _vm.row.results['lower_rotated'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"InfoIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Preview Result")))])],1),(_vm.canDownloadResult)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadFiles([
            _vm.row.results['upper_rotated'],
            _vm.row.results['lower_rotated'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Download Result")))])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.handlePreviewResultClick([
            _vm.row.results['upper'],
            _vm.row.results['lower'] ])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"InfoIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Preview Result deregistered")))])],1),(_vm.canDownloadResultDeregistered)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.downloadFiles([_vm.row.results['upper'], _vm.row.results['lower']])}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"DownloadIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Download Result deregistered")))])],1):_vm._e()],1):_vm._e(),(_vm.canRecalculate)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.replicateCase(_vm.row.id, _vm.row.files)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"CopyIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Recalculate")))])],1):_vm._e(),(_vm.canRejectCase)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.approveCase(_vm.row.id, 'rejected')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"XIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Reject Case")))])],1):_vm._e(),(_vm.canReportCase)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.reportCase(_vm.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"FlagIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Report Case")))])],1):_vm._e(),(_vm.canUpdateStatusToTrash)?_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.updateStatus(_vm.row.id, 'trash')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"TrashIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Trash")))])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }