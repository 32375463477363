<template>
  <div
    class="d-flex align-items-center justify-content-center action"
    v-if="row"
  >
    <div
      :title="$t('Delete finally')"
      v-if="canDeleteFinally"
      class="cursor-pointer mr-1"
      @click="deleteCase(row.id)"
    >
      <feather-icon size="16" icon="TrashIcon" style="color: red" />
    </div>
    <div
      v-if="canEdit"
      class="cursor-pointer"
      @click="$router.push(`/my-cases/${row.id}/edit`)"
    >
      <feather-icon size="16" icon="Edit2Icon" />
    </div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center"
      class=""
    >
      <template #button-content>
        <div class="">
          <ElementIcon v-bind:icon="'barVerticalIcon'" />
        </div>
      </template>

      <b-dropdown-item
        v-if="canDownloadOriginalAI"
        link-class="d-flex align-items-center"
        @click="downloadFiles([row.files['upper'], row.files['lower']])"
      >
        <feather-icon size="16" icon="DownloadIcon" class="mr-50" />
        <span>{{ $t("Download Original used for AI") }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canDownloadOriginal"
        link-class="d-flex align-items-center"
        @click="
          downloadFiles([
            row.files['upper_portal_original'],
            row.files['lower_portal_original'],
          ])
        "
      >
        <feather-icon size="16" icon="DownloadIcon" class="mr-50" />
        <span>{{ $t("Download Original") }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canPreviewOriginalRegistered"
        @click="
          handlePreviewClick(
            row.files['upper_portal'],
            row.files['lower_portal']
          )
        "
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="InfoIcon" class="mr-50" />
        <span>{{ $t("Preview Original Registered") }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canDownloadOriginalRegistered"
        link-class="d-flex align-items-center"
        @click="
          downloadRegisteredFiles([
            row.files['upper_portal'],
            row.files['lower_portal'],
          ])
        "
      >
        <feather-icon size="16" icon="DownloadIcon" class="mr-50" />
        <span>{{ $t("Download Original Registered") }}</span>
      </b-dropdown-item>
      <div v-if="row.results">
        <b-dropdown-item
          @click="
            handlePreviewResultClick([
              row.results['upper_rotated'],
              row.results['lower_rotated'],
            ])
          "
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="InfoIcon" class="mr-50" />
          <span>{{ $t("Preview Result") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canDownloadResult"
          @click="
            downloadFiles([
              row.results['upper_rotated'],
              row.results['lower_rotated'],
            ])
          "
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="DownloadIcon" class="mr-50" />
          <span>{{ $t("Download Result") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="
            handlePreviewResultClick([
              row.results['upper'],
              row.results['lower'],
            ])
          "
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="InfoIcon" class="mr-50" />
          <span>{{ $t("Preview Result deregistered") }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canDownloadResultDeregistered"
          @click="downloadFiles([row.results['upper'], row.results['lower']])"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="DownloadIcon" class="mr-50" />
          <span>{{ $t("Download Result deregistered") }}</span>
        </b-dropdown-item>
      </div>
      <b-dropdown-item
        v-if="canRecalculate"
        @click="replicateCase(row.id, row.files)"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="CopyIcon" class="mr-50" />
        <span>{{ $t("Recalculate") }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canRejectCase"
        @click="approveCase(row.id, 'rejected')"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="XIcon" class="mr-50" />
        <span>{{ $t("Reject Case") }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canReportCase"
        @click="reportCase(row)"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="FlagIcon" class="mr-50" />
        <span>{{ $t("Report Case") }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canUpdateStatusToTrash"
        @click="updateStatus(row.id, 'trash')"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="TrashIcon" class="mr-50" />
        <span>{{ $t("Trash") }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileService from "@/services/file.service";
import ElementIcon from "@/components/elements/Icon.vue";
export default {
  emits: [
    "loadItems",
    "showCreditsModal",
    "getCredits",
    "handlePreviewClick",
    "handlePreviewResultClick",
  ],
  components: {
    ElementIcon,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    configuration: {
      type: Object
    },
    credits: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    canDeleteFinally() {
      return (
        this.row.status == "trash" &&
        this.$can(`${this.$route.meta.permission}.delete`)
      );
    },
    canEdit() {
      return this.$can(`${this.$route.meta.permission}.edit`);
    },
    canDownloadOriginalAI() {
      return (
        (this.row.files["upper"] || this.row.files["lower"]) &&
        this.$can(`${this.$route.meta.permission}.download-original`)
      );
    },
    canDownloadOriginal() {
      return (
        (this.row.files["upper_portal_original"] ||
          this.row.files["lower_portal_original"]) &&
        this.$can(`${this.$route.meta.permission}.download-original`)
      );
    },
    canPreviewOriginalRegistered() {
      return this.row.files["upper_portal"] || this.row.files["lower_portal"];
    },
    canDownloadOriginalRegistered() {
      return this.$can(
        `${this.$route.meta.permission}.download-original-registered`
      );
    },
    canDownloadResult() {
      return this.$can(`${this.$route.meta.permission}.download-result`);
    },
    canDownloadResultDeregistered() {
      return this.$can(
        `${this.$route.meta.permission}.download-result-deregistered`
      );
    },
    canApproveCase() {
      return this.$can(`${this.$route.meta.permission}.approve-case`);
    },
    canRecalculate() {
      return (
        this.$can(`${this.$route.meta.permission}.recalculate`) ||
        this.$can("recalculate-case-from-backoffice.create")
      );
    },
    canRejectCase() {
      return this.$can(`${this.$route.meta.permission}.reject-case`);
    },
    canUpdateStatusToFinished() {
      return this.$can(`${this.$route.meta.permission}.edit`);
    },
    canReportCase() {
      return (
        this.$can("backoffice-ticket.create") &&
        this.$can(`${this.$route.meta.permission}.report-case`)
      );
    },
    canUpdateStatusToTrash() {
      return this.$can(`${this.$route.meta.permission}.edit`);
    },
  },
  methods: {
    async deleteCase(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you really want to completely delete this case?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        await this.$store.dispatch("caseLists/destroy", id).finally(() => {
          this.$emit("loadItems");
        });
      }
    },
    downloadRegisteredFiles(array) {
      FileService.downloadRegisteredFiles(array);
    },
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    handlePreviewClick(upper, lower) {
      this.$emit("handlePreviewClick", { upper, lower });
    },
    handlePreviewResultClick(results) {
      this.$emit("handlePreviewResultClick", results);
    },
    async approveCase(id, status) {
      const title =
        status === "approved"
          ? this.$t("Do you want to approve this record?")
          : this.$t("Do you want to reject this record?");
      const subText =
        status === "approved"
          ? this.$t("Yes approve it!")
          : this.$t("Yes reject it!");
      const confirmed = await this.showConfirmationDialog({
        title: title,
        subText: subText,
      });

      if (confirmed) {
        await this.$store
          .dispatch("caseLists/approveRejectCase", {
            caseId: id,
            status: status,
          })
          .finally(() => {
            this.$emit("loadItems");
          });
      }
    },
    async replicateCase(id, files) {
      const hasPermission = this.$can(
        "recalculate-case-from-backoffice.create"
      );
      if (!hasPermission) {
        var sumCredits = 0;
        if (files["upper"] != null) {
          sumCredits =
            parseFloat(this.configuration?.costsPerUpperJaw) *
            parseFloat(this.row?.amountReconstructedUpperTeeth);
        }
        if (files["lower"] != null) {
          sumCredits =
            sumCredits +
            parseFloat(this.configuration?.costsPerLowerJaw) *
              parseFloat(this.row?.amountReconstructedLowerTeeth);
        }
        if (
          this.configuration?.maximumOverallCosts &&
          sumCredits > parseFloat(this.configuration?.maximumOverallCosts)
        ) {
          sumCredits = parseFloat(this.configuration?.maximumOverallCosts);
        }
        if (this.row.isPaid) {
          if (this.configuration?.recalculationFactor)
            sumCredits =
              sumCredits * parseFloat(this.configuration?.recalculationFactor);
        }
        if (sumCredits > parseFloat(this.credits)) {
          this.$emit("showCreditsModal");
          return;
        }
      }
      const payload = {
        caseId: id,
        extensions: [{ id: "opg-to-3d" }],
      };
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to recalculate this record?"),
        subText: this.$t("Yes, recalculate it!"),
      });

      if (confirmed) {
        await this.$store
          .dispatch("caseLists/replicateCase", payload)
          .finally(() => {
            this.$emit("getCredits");
            this.$emit("loadItems");
          });
      }
    },
    async updateStatus(id, status) {
      const title =
        status === "trash"
          ? this.$t("Do you want to delete this record?")
          : this.$t("Do you want to update this record?");
      const subText =
        status === "trash"
          ? this.$t("Yes delete it!")
          : this.$t("Yes update it!");
      const confirmed = await this.showConfirmationDialog({
        title: title,
        subText: subText,
      });

      if (confirmed) {
        await this.$store
          .dispatch("caseLists/updateStatus", {
            caseId: id,
            status: status,
          })
          .finally(() => {
            this.$emit("loadItems");
          });
      }
    },
    reportCase(reportedCase) {
      this.$store.commit("caseLists/reportCaseID", reportedCase);
      this.$router.push("/tickets/create");
    },
  },
};
</script>

<style lang="scss">
.action {
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    .dropdown-toggle {
      svg {
        width: 22px;
        height: 22px;
        path {
          fill: #0a1724;
          stroke: #0a1724;
        }
      }
      &::after {
        display: none;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
</style>
