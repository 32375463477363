var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-tag-modal","title":"Edit Flag","centered":"","size":"md","hide-footer":""},model:{value:(_vm.toggleModal),callback:function ($$v) {_vm.toggleModal=$$v},expression:"toggleModal"}},[_c('b-form',{staticClass:"modal-form",on:{"submit":function($event){$event.preventDefault();return _vm.updateCase($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Flag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label input-label",attrs:{"for":"flag"}},[_vm._v(_vm._s(_vm.$t("Flag"))+" "),_c('span',{staticStyle:{"color":"#f00"}},[_vm._v("*")])]),_c('multi-select',{attrs:{"options":_vm.existingFlags,"taggable":true,"tag-placeholder":"Add a tag","placeholder":"Select or add a tag","label":"name","track-by":"name"},on:{"tag":_vm.addFlag},model:{value:(_vm.tag.flag),callback:function ($$v) {_vm.$set(_vm.tag, "flag", $$v)},expression:"tag.flag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Set Flag Color"}},[_c('b-dropdown',{staticClass:"form-control form-dropdown",attrs:{"id":"colorDropdown","variant":"link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.tag.tagColor)?_c('p',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-1",style:({
                  backgroundColor: _vm.tag.tagColor,
                  width: '20px',
                  height: '20px',
                  display: 'inline-block',
                  borderRadius: '20px',
                })}),_vm._v(" "+_vm._s(_vm.tag.tagColor || "None")+" ")]):_c('p',[_vm._v(_vm._s(_vm.$t("Select a color")))]),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"20"}})]},proxy:true}])},[_c('div',{staticClass:"color-pettle"},_vm._l((_vm.colors),function(color){return _c('b-dropdown-item',{key:color.value,on:{"click":function($event){_vm.tag.tagColor = color.value}}},[_c('span',{staticClass:"color-box",style:({
                  backgroundColor: color.value,
                })})])}),1)])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-2"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }