<template>
  <div>
    <PageHeader :items="items" />
    <div class="mycases create-case">
      <div class="pageheader">
        <h2>{{ $t("Cases") }}: {{ totalRecords }}</h2>
        <CaseFilters
          :form="form"
          :existingFlags="existingFlags"
          @apply-rating-filter="setFilterRatingValue"
        />
      </div>
      <div class="dextop-table-view">
        <div class="table-responsive api-keys-table case-list-table">
          <vue-good-table
            styleClass="vgt-table striped"
            :columns="columns"
            :fixed-header="false"
            :rows="rows"
            :pagination-options="{
              enabled: true,
            }"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
          >
            <template v-slot:table-column="props">
              <span
                class="d-flex align-items-center position-relative zindex-4"
                style="gap: 15px"
                v-if="props.column.field === 'name'"
              >
                <SelectAllCasesCheckbox @toggleSelectAll="toggleSelectAll" />
                <span>{{ $t("Cases") }}</span>
              </span>
            </template>
            <template slot="table-row" slot-scope="props">
              <ActionDropdown
                :configuration="configuration"
                v-if="props.column.field === 'action' && props.row"
                :row="props.row"
                :credits="credits"
                @handlePreviewClick="handlePreviewClick"
                @handlePreviewResultClick="handlePreviewResultClick"
                @showCreditsModal="showCreditModal = true"
                @getCredits="getCredits"
                @loadItems="loadItems"
              />
              <span
                class="d-flex align-items-center"
                style="gap: 15px"
                v-else-if="props.column.field === 'name'"
              >
                <div class="checkbox-s-group">
                  <input
                    type="checkbox"
                    class="checkbox-input"
                    :id="'case-' + props.row.id"
                    v-model="selectedCases"
                    :value="props.row.id"
                  />
                  <label
                    :for="'case-' + props.row.id"
                    class="checkbox-label m-0"
                  ></label>
                </div>
                <p class="">{{ props.row.name }}</p>
              </span>
              <span
                class="d-flex align-items-center gap-2"
                v-else-if="props.column.field === 'status'"
              >
                <p class="status-tag" :class="props.row.caseStatus">
                  {{ props.row.caseStatus }}
                </p>
                <p
                  v-if="props.row.isApproved == 1"
                  class="status-tag"
                  :class="{ approved: props.row.isApproved == 1 }"
                >
                  {{ $t("Approved") }}
                </p>
                <p
                  v-if="props.row.isRejected == 1"
                  class="status-tag"
                  :class="{ rejected: props.row.isRejected == 1 }"
                >
                  {{ $t("Rejected") }}
                </p>
              </span>
              <span v-else-if="props.column.field === 'Company.companyName'">
                {{ props.row.companyName }}
              </span>
              <span v-else-if="props.column.field === 'upperCase'">
                <div class="failed" v-if="props.row.caseStatus == 'failed'">
                  <ElementIcon v-bind:icon="'xIcon'" />
                </div>
                <div
                  class="failed"
                  v-else-if="props.row.caseStatus == 'unsupported'"
                >
                  <ElementIcon v-bind:icon="'xIcon'" />
                </div>
                <span
                  class="hour-glass"
                  v-else-if="
                    props.row.caseStatus == 'queued' ||
                    props.row.caseStatus == 'in-progress'
                  "
                  ><img src="@/assets/images/svg/hourglass.gif" alt=""
                /></span>
                <div
                  class="d-flex align-items-center justify-content-center flex-column"
                  v-else
                >
                  <img
                    v-if="props.row.results && props.row.results['upper_image']"
                    :src="props.row.upperImageSrc"
                    alt=""
                  />

                  <h4
                    v-if="props.row.results && props.row.results['upper_image']"
                    class="text-center"
                  >
                    {{ $t("Upper") }}
                  </h4>
                  <div
                    v-if="props.row.results && props.row.results['upper_image']"
                  >
                    <StarRating
                      :key="props.row.id"
                      :value="
                        (props.row.ratings &&
                          props.row.ratings.upper_case_rating) ||
                        0
                      "
                      @setRatingValue="
                        setRatingValue($event, props.row.id, 'upper')
                      "
                    />
                  </div>
                </div>
              </span>
              <span v-else-if="props.column.field === 'lowerCase'">
                <div class="failed" v-if="props.row.caseStatus == 'failed'">
                  <ElementIcon v-bind:icon="'xIcon'" />
                </div>
                <div
                  class="failed"
                  v-else-if="props.row.caseStatus == 'unsupported'"
                >
                  <ElementIcon v-bind:icon="'xIcon'" />
                </div>
                <span
                  class="hour-glass"
                  v-else-if="
                    props.row.caseStatus == 'queued' ||
                    props.row.caseStatus == 'in-progress'
                  "
                  ><img src="@/assets/images/svg/hourglass.gif" alt=""
                /></span>
                <div
                  class="d-flex align-items-center justify-content-center flex-column"
                  v-else
                >
                  <img
                    v-if="props.row.results && props.row.results['lower_image']"
                    :src="props.row.lowerImageSrc"
                    alt=""
                  />
                  <h4
                    v-if="props.row.results && props.row.results['lower_image']"
                    class="text-center"
                  >
                    {{ $t("Lower") }}
                  </h4>
                  <div
                    v-if="props.row.results && props.row.results['lower_image']"
                  >
                    <StarRating
                      :key="props.row.id"
                      :value="
                        (props.row.ratings &&
                          props.row.ratings.lower_case_rating) ||
                        0
                      "
                      @setRatingValue="
                        setRatingValue($event, props.row.id, 'lower')
                      "
                    />
                  </div>
                </div>
              </span>
              <span v-else-if="props.column.field === 'createdAt'">
                <p class="">{{ $dateFormatter(props.row.creation_date, $i18n.locale) }}</p>
              </span>
              <span v-else-if="props.column.field === 'isConnected'">
                <div class="d-flex align-items-center gap-2">
                  <p class="status-tag" v-if="props.row.isConnected === 1">
                    {{ $t("Connected") }}
                  </p>
                </div>
              </span>
              <span
                class="d-flex align-items-center"
                v-else-if="props.column.field === 'flagId'"
              >
                <div
                  class="cursor-pointer tag"
                  :style="{
                    backgroundColor: props.row.tagColor,
                  }"
                  @click="openModal(props.row)"
                  :title="props.row.flag ? props.row.flag.name : ''"
                ></div>
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-center flex-wrap mt-2">
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecords"
                    :per-page="form.perPage"
                    @input="(value) => onPageChange({ currentPage: value })"
                    aria-controls="case-list"
                  ></b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
      <div class="reponsive-view-cards">
        <b-row>
          <b-col cols="12">
            <span
              class="d-flex align-items-center position-relative zindex-4 gap-1 mb-1"
            >
              <SelectAllCasesCheckbox @toggleSelectAll="toggleSelectAll" />
              <strong>{{ $t("Select all Cases") }}</strong>
            </span>
          </b-col>
          <b-col
            cols="12"
            lg="4"
            md="6"
            v-for="(row, index) in rows"
            :key="index"
          >
            <div class="card">
              <div
                class="card-header d-flex align-items-center justify-content-between"
              >
                <span class="d-flex align-items-center gap-1">
                  <div class="checkbox-s-group">
                    <input
                      type="checkbox"
                      class="checkbox-input"
                      :id="'case-' + row.id"
                      v-model="selectedCases"
                      :value="row.id"
                    />
                    <label
                      :for="'case-' + row.id"
                      class="checkbox-label m-0"
                    ></label>
                  </div>
                  <h4 class="m-0">{{ row.name }}</h4>
                </span>
                <span v-if="row">
                  <ActionDropdown
                    :configuration="configuration"
                    :row="row"
                    :credits="credits"
                    @handlePreviewClick="handlePreviewClick"
                    @handlePreviewResultClick="handlePreviewResultClick"
                    @showCreditsModal="showCreditModal = true"
                    @getCredits="getCredits"
                    @loadItems="loadItems"
                  />
                </span>
              </div>
              <div class="card-body pt-0">
                <ul class="card-list">
                  <li>
                    <div class="left">
                      <strong>{{ $t("Company") }}:</strong>
                    </div>
                    <div class="right">{{ row.companyName }}</div>
                  </li>
                  <li>
                    <div class="left">
                      <strong>{{ $t("Upper Case") }}:</strong>
                    </div>
                    <div class="right">
                      <span>
                        <div class="failed" v-if="row.caseStatus == 'failed'">
                          <ElementIcon v-bind:icon="'xIcon'" />
                        </div>
                        <div
                          class="failed"
                          v-else-if="row.caseStatus == 'unsupported'"
                        >
                          <ElementIcon v-bind:icon="'xIcon'" />
                        </div>
                        <span
                          class="hour-glass"
                          v-else-if="
                            row.caseStatus == 'queued' ||
                            row.caseStatus == 'in-progress'
                          "
                          ><img src="@/assets/images/svg/hourglass.gif" alt=""
                        /></span>
                        <div
                          class="d-flex align-items-center justify-content-center flex-column"
                          v-else
                        >
                          <img
                            v-if="row.results && row.results['upper_image']"
                            :src="row.upperImageSrc"
                            alt=""
                            class="mb-2"
                          />

                          <h4
                            v-if="row.results && row.results['upper_image']"
                            class="text-center"
                          >
                            {{ $t("Upper") }}
                          </h4>
                          <div v-if="row.results && row.results['upper_image']">
                            <StarRating
                              :key="row.id"
                              :value="
                                (row.ratings &&
                                  row.ratings.upper_case_rating) ||
                                0
                              "
                              @setRatingValue="
                                setRatingValue($event, row.id, 'upper')
                              "
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li class="d-flex">
                    <div class="left">
                      <strong>{{ $t("Lower Case") }}:</strong>
                    </div>
                    <div class="right">
                      <span>
                        <div class="failed" v-if="row.caseStatus == 'failed'">
                          <ElementIcon v-bind:icon="'xIcon'" />
                        </div>
                        <div
                          class="failed"
                          v-else-if="row.caseStatus == 'unsupported'"
                        >
                          <ElementIcon v-bind:icon="'xIcon'" />
                        </div>
                        <span
                          class="hour-glass"
                          v-else-if="
                            row.caseStatus == 'queued' ||
                            row.caseStatus == 'in-progress'
                          "
                          ><img src="@/assets/images/svg/hourglass.gif" alt=""
                        /></span>
                        <div
                          class="d-flex align-items-center justify-content-center flex-column"
                          v-else
                        >
                          <img
                            v-if="row.results && row.results['lower_image']"
                            :src="row.lowerImageSrc"
                            alt=""
                            class="mb-2"
                          />
                          <h4
                            v-if="row.results && row.results['lower_image']"
                            class="text-center"
                          >
                            {{ $t("Lower") }}
                          </h4>
                          <div v-if="row.results && row.results['lower_image']">
                            <StarRating
                              :key="row.id"
                              :value="
                                (row.ratings &&
                                  row.ratings.lower_case_rating) ||
                                0
                              "
                              @setRatingValue="
                                setRatingValue($event, row.id, 'lower')
                              "
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li class="d-flex">
                    <div class="left">
                      <strong>{{ $t("Status") }}:</strong>
                    </div>
                    <div class="right">
                      <span class="d-flex align-items-center gap-2">
                        <p class="status-tag" :class="row.caseStatus">
                          {{ row.caseStatus }}
                        </p>
                        <p
                          v-if="row.isApproved == 1"
                          class="status-tag"
                          :class="{ approved: row.isApproved == 1 }"
                        >
                          {{ $t("Approved") }}
                        </p>
                        <p
                          v-if="row.isRejected == 1"
                          class="status-tag"
                          :class="{ rejected: row.isRejected == 1 }"
                        >
                          {{ $t("Rejected") }}
                        </p>
                      </span>
                    </div>
                  </li>
                  <li class="d-flex">
                    <div class="left">
                      <strong>{{ $t("Connected Case") }}:</strong>
                    </div>
                    <div class="right">
                      <span>
                        <div class="d-flex align-items-center gap-2">
                          <p class="status-tag" v-if="row.isConnected === 1">
                            {{ $t("Connected") }}
                          </p>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li class="d-flex">
                    <div class="left">
                      <strong>{{ $t("Date") }}:</strong>
                    </div>
                    <div class="right">
                      <span>
                        <p class="">{{ $dateFormatter(row.creation_date, $i18n.locale ) }}</p>
                      </span>
                    </div>
                  </li>
                  <li class="d-flex">
                    <div class="left">
                      <strong>{{ $t("Flag") }}:</strong>
                    </div>
                    <div class="right">
                      <span class="d-flex align-items-center">
                        <div
                          class="tag"
                          :style="{
                            backgroundColor: row.tagColor,
                          }"
                          @click="openModal(row)"
                          :title="row.flag ? row.flag.name : ''"
                        ></div>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-center mt-2">
              <b-pagination
                :value="1"
                :total-rows="totalRecords"
                :per-page="form.perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => onPageChange({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
      <!--==================================-->

      <CookieButton>
        <template #cookieBtns>
          <button
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary mr-1"
            @click="downloadCaseFiles()"
            v-if="$can(`${$route.meta.permission}.download-original`)"
          >
            {{ $t("Download") }}
          </button>
          <button
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary mr-1"
            @click="approveCases('approved')"
            v-if="$can(`${$route.meta.permission}.approve-case`)"
          >
            {{ $t("Approve Cases") }}
          </button>
          <button
            @click="approveCases('reject')"
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary"
            v-if="$can(`${$route.meta.permission}.reject-case`)"
          >
            {{ $t("Reject Cases") }}
          </button>
        </template>
      </CookieButton>
      <EditFlagModal
        v-if="showModal"
        :tag="tag"
        :selectedId="selectedId"
        :existingFlags="existingFlags"
        :showModal="showModal"
        @loadFlags="loadFlags"
        @loadItems="loadItems"
        @closeModal="showModal = false"
      />
      <!---===================================-->
      <!---===================================-->
      <div class="c-modal" v-show="showPreviewModal">
        <div class="c-modal-content">
          <div class="c-modal-header">
            <h3>{{ $t("Preview") }}</h3>
            <div class="x-icon" @click="closePreviewModal">
              <feather-icon size="16" icon="XIcon" />
            </div>
          </div>
          <div class="c-modal-body">
            <PreviewModel
              v-if="showPreviewModal"
              :upper="currentUpper"
              :lower="currentLower"
            />
          </div>
        </div>
      </div>
      <!---===================================-->
      <!---===================================-->
      <div class="c-modal" v-show="showPreviewResultModal">
        <div class="c-modal-content">
          <div class="c-modal-header">
            <h3>{{ $t("Preview") }}</h3>
            <div class="x-icon" @click="closePreviewResultModal">
              <feather-icon size="16" icon="XIcon" />
            </div>
          </div>
          <div class="c-modal-body">
            <PreviewResultModel
              v-if="showPreviewResultModal"
              :results="results"
            />
          </div>
        </div>
      </div>
      <!---===================================-->
      <!---===================================-->
      <b-modal
        id="edit-tag-modal"
        v-model="showCreditModal"
        title="Not enough DentalTwin Coins"
        centered
        size="md"
        hide-footer
      >
        {{ $t("You do not have enough DentalTwin Coins to recalculate the case") }}
        <div class="d-flex align-items-center justify-content-end mt-2">
          <b-button @click="showCreditModal = false" variant="primary">{{
            $t("Ok")
          }}</b-button>
        </div>
      </b-modal>
      <!---===================================-->
      <!---===================================-->
    </div>
  </div>
</template>

<script>
import CookieButton from "@/components/elements/CookieButton.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import { BLink, BRow, BCol, BImg, BPagination } from "bootstrap-vue";
import FileService from "@/services/file.service";
import { mapGetters } from "vuex";
import MultiSelect from "vue-multiselect";
import PreviewModel from "@/components/PreviewModel.vue";
import PreviewResultModel from "@/components/PreviewResultModel.vue";
import PageHeader from "@/components/PageHeader.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import StarRating from "@/components/elements/StarRating.vue";
import { debounce } from "@/utils/debounce";
import CaseFilters from "./components/CaseFilters.vue";
import SelectAllCasesCheckbox from "./components/SelectAllCasesCheckbox.vue";
import ActionDropdown from "./components/ActionDropdown.vue";
import EditFlagModal from "./components/EditFlagModal.vue";
export default {
  components: {
    ElementIcon,
    BRow,
    BCol,
    BLink,
    BImg,
    BPagination,
    MultiSelect,
    CookieButton,
    PreviewModel,
    PreviewResultModel,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    StarRating,
    CaseFilters,
    SelectAllCasesCheckbox,
    ActionDropdown,
    EditFlagModal,
  },
  data() {
    return {
      selectedJaw: "both",
      showModal: false,
      showCreditModal: false,
      showPreviewModal: false,
      showPreviewResultModal: false,
      currentTag: {
        index: null,
        text: "",
        color: "#ffffff",
      },
      intervalId: null,
      previousItems: null,
      currentPage: 1,
      rows: [],
      selectedId: null,
      selectAll: false,
      totalRecords: 0,
      credits: 0,
      selectedCases: [],
      tag: {
        flag: null,
        tagColor: "",
      },
      sortBy: "createdAt",
      sortOrder: "desc",
      form: {
        search: "",
        status: "all",
        selectedFilter: "all",
        perPage: 25,
        caseStatus: "all",
      },
      filteredCases: [],
      currentTag: {
        color: "",
      },
      costsPerUpperJaw: "",
      costsPerLowerJaw: "",
      recalculationFactor: "",
      existingFlags: [],
      currentUpper: null,
      currentLower: null,
      results: null,
      filterRatingValue: null,
    };
  },
  computed: {
    ...mapGetters("globalConfiguration", ["configuration"]),
    ...mapGetters(["showLoader"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Cases"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("Case Name"),
          field: "name",
        },
        {
          label: this.$t("Company"),
          field: "Company.companyName",
        },
        {
          label: this.$t("Upper Case"),
          field: "upperCase",
          sortable: false,
        },
        {
          label: this.$t("Lower Case"),
          field: "lowerCase",
          sortable: false,
        },
        {
          label: this.$t("Status"),
          field: "status",
        },
        {
          label: this.$t("Connected Case"),
          field: "isConnected",
        },
        {
          label: this.$t("Date"),
          field: "createdAt",
        },
        {
          label: this.$t("Flag"),
          field: "flagId",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
  },
  methods: {
    async setFilterRatingValue(event) {
      this.filterRatingValue = event;
      await this.loadItems();
    },
    async setRatingValue(event, id, jawType) {
      await this.$store.dispatch("caseLists/saveRating", {
        caseId: id,
        ...(jawType === "upper"
          ? { upperCaseRating: event }
          : { lowerCaseRating: event }),
      });
    },
    handlePreviewClick(files) {
      this.currentUpper = files.upper;
      this.currentLower = files.lower;
      this.showPreviewModal = true;
    },
    handlePreviewResultClick(array) {
      this.results = array;
      this.showPreviewResultModal = true;
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    onSortChange(params) {
      this.sortOrder = params[0].type;
      if (params[0].type == "none") this.sortOrder = "asc";
      this.sortBy = params[0].field;
      this.loadItems();
    },
    async loadFlags() {
      let response = await this.$store.dispatch("flags/list");
      this.existingFlags = response?.data?.data;
    },
    async loadItems() {
      await this.$store
        .dispatch("caseLists/list", {
          page: this.page,
          ...this.form,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          selectedFilter: this.form.selectedFilter,
          starRating: this.filterRatingValue,
        })
        .then((res) => {
          this.$store.commit("showLoader", false);
          this.rows = res?.data?.data;
          this.totalRecords = res?.data?.meta?.total;
        })
        .finally(() => {
          this.$store.commit("showLoader", false);
        });
    },
    async approveCases(status) {
      const title =
        status === "approved"
          ? this.$t("Do you want to approve these records?")
          : this.$t("Do you want to reject these records?");
      const subText =
        status === "approved"
          ? this.$t("Yes approve them!")
          : this.$t("Yes reject them!");
      const confirmed = await this.showConfirmationDialog({
        title: title,
        subText: subText,
      });

      if (confirmed) {
        await this.$store
          .dispatch("caseLists/approveStatuses", {
            caseIds: this.selectedCases,
            status: status,
          })
          .finally(() => {
            this.selectedCases = [];
            this.loadItems();
          });
      }
    },
    async downloadCaseFiles() {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to download the selected case files?"),
        subText: this.$t("Yes download them!"),
      });

      if (confirmed) {
        var cases = this.rows.filter((row) =>
          this.selectedCases.includes(row.id)
        );
        // Loop through each case using forEach
        cases.forEach((caseItem) => {
          FileService.downloadFiles([
            caseItem.files["upper_portal_original"],
            caseItem.files["lower_portal_original"],
          ]);
        });
        this.selectedCases = [];
      }
    },
    openModal(caseItem) {
      if (this.$can(`${this.$route.meta.permission}.edit-flag`)) {
        this.selectedId = caseItem.id;
        this.tag.tagColor = caseItem.tagColor;
        this.tag.flag = caseItem.flag;
        this.showModal = true;
      }
    },
    async getCredits() {
      const creditResponse = await this.$store.dispatch("customers/getCredits");
      this.credits = creditResponse?.data?.credits ?? 0;
    },
    toggleSelectAll(value) {
      this.selectAll = value;
      if (this.selectAll) {
        // Map over rows to extract all IDs
        this.selectedCases = this.rows.map((row) => row.id);
      } else {
        this.selectedCases = [];
      }
    },
    closePreviewModal() {
      this.showPreviewModal = false;
    },
    closePreviewResultModal() {
      this.showPreviewResultModal = false;
    },
  },
  async mounted() {
    this.$store.commit("showLoader", true);
    this.loadItems();
    this.loadFlags();
    this.getCredits();
    await this.$store.dispatch("globalConfiguration/show");
    this.costsPerUpperJaw = this.configuration.costsPerUpperJaw ?? "";
    this.costsPerLowerJaw = this.configuration.costsPerLowerJaw ?? "";
    this.recalculationFactor = this.configuration.recalculationFactor ?? "";
    this.$store.commit("showLoader", false);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  watch: {
    selectedCases(newVal) {
      this.selectAll = newVal.length === this.rows.length;
    },
    form: {
      handler() {
        this.debouncedFetch();
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("cookie-layout");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("cookie-layout");
    next();
  },
};
</script>
<style lang="scss">
.form-dropdown {
  width: 100%;
  .dropdown-toggle {
    padding: 0 !important;
    height: auto !important;
    color: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
      display: none;
    }
  }
}
.hour-glass {
  img {
    width: 50px;
  }
}
.tag {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 1px solid #0a1724;
}

.tag.flag {
  background: #f00;
  border-color: #f00;
}
.failed {
  border: 1px solid #f00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: #f00;
    }
  }
}
</style>
<style scoped>
.hidden {
  visibility: visible;
  display: block;
}
</style>
