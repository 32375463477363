<template>
  <!-- B-Modal for editing tag -->
  <b-modal
    id="edit-tag-modal"
    v-model="toggleModal"
    title="Edit Flag"
    centered
    size="md"
    hide-footer
  >
    <b-form @submit.prevent="updateCase" class="modal-form">
      <validation-observer ref="simpleRules">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Flag"
            rules="required"
          >
            <label class="form-label input-label" for="flag"
              >{{ $t("Flag") }}&nbsp;<span style="color: #f00">*</span>
            </label>
            <multi-select
              v-model="tag.flag"
              :options="existingFlags"
              :taggable="true"
              tag-placeholder="Add a tag"
              placeholder="Select or add a tag"
              @tag="addFlag"
              label="name"
              track-by="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Set Flag Color">
          <b-dropdown
            id="colorDropdown"
            class="form-control form-dropdown"
            variant="link"
          >
            <template #button-content>
              <p class="d-flex align-items-center" v-if="tag.tagColor">
                <span
                  class="mr-1"
                  :style="{
                    backgroundColor: tag.tagColor,
                    width: '20px',
                    height: '20px',
                    display: 'inline-block',
                    borderRadius: '20px',
                  }"
                >
                </span>
                {{ tag.tagColor || "None" }}
              </p>
              <p v-else>{{ $t("Select a color") }}</p>
              <feather-icon icon="ChevronDownIcon" size="20" />
            </template>
            <div class="color-pettle">
              <b-dropdown-item
                v-for="color in colors"
                :key="color.value"
                @click="tag.tagColor = color.value"
              >
                <span
                  class="color-box"
                  :style="{
                    backgroundColor: color.value,
                  }"
                >
                </span>
                <!-- {{ color.name }} -->
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-form-group>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <b-button type="submit" variant="primary">{{ $t("Save") }}</b-button>
        </div>
      </validation-observer>
    </b-form>
  </b-modal>
</template>

<script>
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import MultiSelect from "vue-multiselect";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Object,
      default: () => ({}),
    },
    existingFlags: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: String || Number,
      default: "",
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    MultiSelect,
  },
  data() {
    return {
      toggleModal: this.showModal,
      colors: [
        { name: "Red", value: "#ff0000" },
        { name: "Green", value: "#00ff00" },
        { name: "Blue", value: "#0000ff" },
        { name: "Yellow", value: "#ffff00" },
        { name: "Magenta", value: "#ff00ff" },
        { name: "Cyan", value: "#00ffff" },
        { name: "Maroon", value: "#800000" },
        { name: "Dark Green", value: "#008000" },
        { name: "Navy", value: "#000080" },
        { name: "Gray", value: "#808080" },
        { name: "Orange", value: "#ffa500" },
        { name: "Purple", value: "#800080" },
        { name: "Teal", value: "#008080" },
        { name: "Olive", value: "#808000" },
        { name: "Silver", value: "#c0c0c0" },
        { name: "Gold", value: "#ffd700" },
        { name: "Coral", value: "#ff7f50" },
        { name: "Salmon", value: "#fa8072" },
        { name: "Lavender", value: "#e6e6fa" },
        { name: "Sky Blue", value: "#87ceeb" },
        { name: "Slate Gray", value: "#708090" },
        { name: "Chocolate", value: "#d2691e" },
        { name: "Crimson", value: "#dc143c" },
        { name: "Periwinkle", value: "#ccccff" },
        { name: "Mint", value: "#98ff98" },
        { name: "Peach", value: "#ffcc99" },
        { name: "Ivory", value: "#fffff0" },
        { name: "Plum", value: "#dda0dd" },
        { name: "Turquoise", value: "#40e0d0" },
        { name: "Beige", value: "#f5f5dc" },
        { name: "Orchid", value: "#da70d6" },
        { name: "Tomato", value: "#ff6347" },
      ],
    };
  },
  watch: {
    toggleModal(val) {
      if (!val) {
        this.$emit("closeModal");
      }
    },
  },
  methods: {
    async addFlag(newTag) {
      await this.$store.dispatch("flags/create", {
        name: newTag,
        color: this.tag.tagColor,
      });
      this.$emit("loadFlags");
    },
    async updateCase() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store
            .dispatch("caseLists/updateFlag", {
              caseId: this.selectedId,
              tagColor: this.tag.tagColor ?? "#ffffff",
              flag: this.tag.flag?.id ?? null,
            })
            .then((res) => {
              this.$emit("loadItems");
              this.$emit("closeModal");
            });
        }
      });
    },
  },
};
</script>
